import React from 'react';
import { Link } from '@inertiajs/react';

const Footer = () => {
    return (
        <footer className="py-4 text-center text-sm text-black dark:text-white/70">
            <div>
                SmashFile&reg; - {new Date().getFullYear()} - by{' '}
                <a
                    href="https://thefreakyfactory.com"
                    className="text-blue-500 hover:underline"
                    target="_blank"
                    rel="noreferrer"
                >
                    TheFreakyFactory
                </a>
            </div>
            <div>
                <Link href={route('pricing')}>Pricing</Link> |{' '}
                <Link href={route('docs.index')}>API docs</Link> |{' '}
                <Link href={route('security')}>Security</Link> |{' '}
                <Link href={route('about')}>About us</Link> |{' '}
                <Link href={route('terms')}>Terms & conditions</Link> |{' '}
                <Link href={route('privacy')}>Privacy</Link> |{' '}
                <Link href={route('changelog')}>Changelog</Link> |{' '}
                <a href="https://smashfile.instatus.com/" target="_blank" rel="noreferrer">Status</a> |{' '}
                <Link href={route('contact')}>Contact us</Link>
            </div>
        </footer>
    );
};

export default Footer;
