'use client'

import {useState} from 'react'
import {
    Dialog,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
} from '@headlessui/react'
import {
    DocumentIcon,
    Bars3Icon,
    ArrowsPointingInIcon,
    ArrowRightStartOnRectangleIcon,
    PhotoIcon,
    XMarkIcon,
    Square3Stack3DIcon,
} from '@heroicons/react/24/outline'
import {ChevronDownIcon, PhoneIcon, PlayCircleIcon} from '@heroicons/react/20/solid'
import {FileLogo} from "@/Components/ApplicationLogo";
import {User} from "@/types";
import {Link} from "@inertiajs/react";

const toolsPdf = [
    {
        name: 'Compress PDF',
        description: 'Get a better understanding of your traffic',
        href: route('tools.tool', {format: 'pdf', action: 'compress',}),
        icon: ArrowsPointingInIcon
    },
    {
        name: 'PDF to Image',
        description: 'Get a better understanding of your traffic',
        href: route('tools.tool', {format: 'pdf', action: 'img',}),
        icon: PhotoIcon
    },
    {
        name: 'PDF to OCR',
        description: 'Convert scanned documents into editable and searchable PDF',
        href: route('tools.tool', {format: 'pdf', action: 'ocr',}),
        icon: ArrowRightStartOnRectangleIcon
    },
    {
        name: 'Merge PDF',
        description: 'Combine PDFs into a single file',
        href: route('tools.tool', {format: 'pdf', action: 'merge',}),
        icon: Square3Stack3DIcon
    },
]
const toolsImg = [
    {
        name: 'Compress Image',
        description: 'Speak directly to your customers',
        href: route('tools.tool', {format: 'img', action: 'compress',}),
        icon: ArrowsPointingInIcon
    },
    {
        name: 'Image to PDF',
        description: 'Your customers’ data will be safe and secure',
        href: route('tools.tool', {format: 'img', action: 'pdf',}),
        icon: DocumentIcon
    },
    {
        name: 'Image grayscale',
        description: 'Connect with third-party tools',
        href: route('tools.tool', {format: 'img', action: 'grayscale',}),
        icon: PhotoIcon
    },
    {
        name: 'Image to WEBP',
        description: 'Optimize images for web',
        href: route('tools.tool', {format: 'img', action: 'webp',}),
        icon: PhotoIcon
    },
    {
        name: 'Image OCR',
        description: 'Get the text from images',
        href: route('tools.tool', {format: 'img', action: 'ocr',}),
        icon: ArrowRightStartOnRectangleIcon
    },
]

const callsToAction = [
    {name: 'Api', href: route('docs.index'), icon: PlayCircleIcon},
    {name: 'Contact sales', href: route('contact'), icon: PhoneIcon},
]


type HeaderProps = {
    user?: User
}

export default function Header({user}: HeaderProps) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <header className="text-gray-800 dark:text-gray-200">
            <nav aria-label="Global" className="mx-auto flex items-center justify-between p-4 lg:px-6">
                <div className="flex lg:flex-1">
                    <a href={route('home')} className="-m-1.5 p-1.5">
                        <span className="sr-only">SmashFile</span>
                        <FileLogo className="w-auto h-8"/>
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(true)}
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon aria-hidden="true" className="size-6"/>
                    </button>
                </div>
                <PopoverGroup className="hidden lg:flex lg:gap-x-12">
                    <a href={route('tools')} className="text-sm/6 font-semibold text-gray-800 dark:text-gray-200">
                        All tools
                    </a>
                    <Popover className="relative">
                        <PopoverButton
                            className="flex items-center gap-x-1 text-sm/6 font-semibold text-gray-800 dark:text-gray-200">
                            Pdf Tools
                            <ChevronDownIcon aria-hidden="true"
                                             className="size-5 flex-none text-gray-400 dark:text-gray-300"/>
                        </PopoverButton>

                        <PopoverPanel
                            transition
                            className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white dark:bg-gray-800 shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <div className="p-4">
                                {toolsPdf.map((item) => (
                                    <div
                                        key={item.name}
                                        className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-50 dark:hover:bg-gray-700"
                                    >
                                        <div
                                            className="flex size-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white dark:group-hover:bg-gray-600">
                                            <item.icon aria-hidden="true"
                                                       className="size-6 text-gray-500 dark:text-gray-400 group-hover:text-indigo-600 dark:group-hover:text-gray-50"/>
                                        </div>
                                        <div className="flex-auto">
                                            <a href={item.href}
                                               className="block font-semibold text-gray-800 dark:text-gray-200">
                                                {item.name}
                                                <span className="absolute inset-0"/>
                                            </a>
                                            <p className="mt-1 text-gray-500 dark:text-gray-400">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50 dark:bg-gray-900">
                                {callsToAction.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="flex items-center justify-center gap-x-2.5 p-3 text-sm/6 font-semibold text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                                    >
                                        <item.icon aria-hidden="true" className="size-5 flex-none text-gray-400"/>
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                        </PopoverPanel>
                    </Popover>

                    <Popover className="relative">
                        <PopoverButton
                            className="flex items-center gap-x-1 text-sm/6 font-semibold text-gray-800 dark:text-gray-200">
                            Image tools
                            <ChevronDownIcon aria-hidden="true"
                                             className="size-5 flex-none text-gray-400 dark:text-gray-300"/>
                        </PopoverButton>

                        <PopoverPanel
                            transition
                            className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white dark:bg-gray-800 shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <div className="p-4">
                                {toolsImg.map((item) => (
                                    <div
                                        key={item.name}
                                        className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-50 dark:hover:bg-gray-700"
                                    >
                                        <div
                                            className="flex size-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white dark:group-hover:bg-gray-600">
                                            <item.icon aria-hidden="true"
                                                       className="size-6 text-gray-500 dark:text-gray-400 group-hover:text-indigo-600 dark:group-hover:text-gray-50"/>
                                        </div>
                                        <div className="flex-auto">
                                            <a href={item.href}
                                               className="block font-semibold text-gray-800 dark:text-gray-200">
                                                {item.name}
                                                <span className="absolute inset-0"/>
                                            </a>
                                            <p className="mt-1 text-gray-500 dark:text-gray-400">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50 dark:bg-gray-900">
                                {callsToAction.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="flex items-center justify-center gap-x-2.5 p-3 text-sm/6 font-semibold text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                                    >
                                        <item.icon aria-hidden="true" className="size-5 flex-none text-gray-400"/>
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                        </PopoverPanel>
                    </Popover>
                </PopoverGroup>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    {user ? (
                        <Link href={route('dashboard')}>Dashboard</Link>
                    ) : (
                        <Link href={route('login')}
                              className="text-sm/6 font-semibold text-gray-800 dark:text-gray-200">
                            Log in <span aria-hidden="true">&rarr;</span>
                        </Link>
                    )}
                </div>
            </nav>
            <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
                <div className="fixed inset-0 z-10"/>
                <DialogPanel
                    className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white dark:bg-gray-800 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">SmashFile</span>
                            <FileLogo className="w-auto h-10"/>
                        </a>
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(false)}
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon aria-hidden="true" className="size-6"/>
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">

                                <Disclosure as="div" className="-mx-3">
                                    <DisclosureButton
                                        className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7 font-semibold text-gray-800 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700">
                                        All Tools
                                        <ChevronDownIcon aria-hidden="true"
                                                         className="size-5 flex-none group-data-[open]:rotate-180"/>
                                    </DisclosureButton>
                                    <DisclosurePanel className="mt-2 space-y-2">
                                        {[...toolsPdf, ...toolsImg, ...callsToAction].map((item) => (
                                            <DisclosureButton
                                                key={item.name}
                                                as="a"
                                                href={item.href}
                                                className="block rounded-lg py-2 pl-6 pr-3 text-sm/7 font-semibold text-gray-800 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700"
                                            >
                                                {item.name}
                                            </DisclosureButton>
                                        ))}
                                    </DisclosurePanel>
                                </Disclosure>

                                <Link
                                    href={route('docs.index')}
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-800 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700"
                                >
                                    API Docs
                                </Link>
                                <Link
                                    href={route('about')}
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-800 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700"
                                >
                                    About us
                                </Link>
                            </div>
                            <div className="py-6">
                                {user ? (
                                    <Link href={route('dashboard')}>Dashboard</Link>
                                ) : (
                                    <Link
                                        href={route('login')}
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base/7 font-semibold text-gray-800 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700"
                                    >
                                        Log in
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>
    )
}
